@import "../../variables.scss";
@import "./variables.scss";
@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/components/navbar";

.navbar {
  position: relative;
  z-index: 30;
  background-color: $primary-color;

  &.transparent {
    background-color: transparent;
  }

  &.light {
    background-color: $white;
  }

  &.floating {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &.shadow {
    border-bottom: 1px solid #f2f2f3;
    box-shadow: $box-shadow-bottom;
  }

  &.curved {
    border-bottom-left-radius: 40px;

    &::before {
      content: "";
      position: absolute;
      right: -42px;
      height: $nav-height * 2;
      width: $nav-height * 2;
      top: 17px;
      border: 40px solid $primary-color;
      border-radius: 50%;
      clip-path: inset(0 0 50% 50%);
    }
  }

}

.navbarInner {
  height: $nav-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $background-color;

  &.transparent {
    background-color: transparent;
  }

  &.active {
    background-color: $background-color;
  }
}

.menu {
  composes: navbar-menu;
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  box-shadow: none;
  background: $background-color;
}

.menuActive {
  composes: menu is-active;
  box-shadow: $box-shadow-bottom;
}

.navbarStart {
  composes: navbar-start;

  a {
    background-color: transparent !important;
  }
}

.navbarItem {
  composes: navbar-item;
  text-decoration: none;
  background: transparent;
  border: 0;
  width: 100%;
  padding: $spacing-xs;
  display: flex;
  color: $main-text-color;

  &:not(:last-child) {
    border-bottom: 1px rgba(0,0,0,.08) solid;
  }

  &.active {
    color: $active-color;
  }
}

.menuItemIcon {
  font-size: 22px;
  line-height: 26px;
  padding-right: $spacing-xs;
  margin: auto 0;

  > svg {
    min-width: $spacing-sm;
  }
}

.menuItemText {
  font-size: 20px;
  font-weight: 500;
}

.overlay {
  display: none;
  position: fixed;
  inset: $nav-height 0 0 0;
  background-color: rgba(30, 30, 36, .8);
}

.activeOverlay {
  composes: overlay;
  display: block;
}
