@import "../../../variables.scss";
@import "../variables.scss";

.burgerButton {
  border: 0;
  background: none;
  height: $nav-height;
  width: $nav-height;
  position: relative;
  padding: 0;

  &.inverted >div {
    background-color: $light-text-color;
  }


  >div {
    position: absolute;
    left: calc(50% - 9px);
    width: 18px;
    background-color: $main-text-color;
    height: 2px;
    border-radius: 2px;
    transition-property: opacity, transform;
    transition-duration: .2s;

    &:first-child {
      top: calc(50% - 6px);
    }

    &:nth-child(2) {
      top: calc(50%);
      width: 14px;
    }

    &:last-child {
      top: calc(50% + 6px);
    }
  }
}

.activeBurgerButton {
  composes: burgerButton;

  >div {

    &:first-child {
      transform: translateY(6px) rotate(45deg);
    }

    &:nth-child(2) {
      opacity: 0;
      transform: translateX(-3px);
    }

    &:last-child {
      transform: translateY(-6px) rotate(-45deg);
    }
  }
}
