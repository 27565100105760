.verticalCenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  flex-basis: 100%;
  padding: 20px;
  overflow-y: auto;
}

.centerText {
  text-align: center;
}

.disableTouch {
  touch-action: none;
  pointer-events: none;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
